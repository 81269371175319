<template>
  <div class="product-wrap product-factory">
    <Header is-active="product" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-factory">
      <div class="txt"></div>
    </div>
    <div class="white-wrap">
      <div class="fz-big align-ct"><h4>智慧工厂是什么</h4></div>
      <div class="txt-box align-ct">
        <p>智慧工厂是现代工厂信息化发展的新阶段。是在数字化工厂的基础上，利用物联网的技术和设备监控技术加强信息管理和服务；</p>
        <p>清楚掌握产销流程、提高生产过程的可控性、减少生产线上人工的干预、即时正确地采集生产线数据，</p>
        <p>以及合理的生产计划编排与生产进度。并加上绿色智能的手段和智能系统等新兴技术于一体，</p>
        <p>构建一个高效节能的、绿色环保的、环境舒适的人性化工厂。</p>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="fz-big align-ct"><h4>平台优势</h4></div>
      <div class="ys-pic">
        <img src="../../assets/img/product/ptys.png" alt="平台优势">
      </div>
    </div>
    <div class="white-wrap">
      <div class="fz-big align-ct"><h4>平台架构</h4></div>
      <div class="jg-pic">
        <img src="../../assets/img/product/factory/jg.png" alt="平台架构">
      </div>
    </div>
    <div class="blue-wrap">
      <el-row class="max-center pd-5">
        <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
          <div class="bs-pic"></div>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
          <div class="bs-info">
            <div class="fz-big align-lt"><h4>平台部署</h4></div>
            <p class="align-lt">支持公有云、私有云和混合云（公有云+私有云）三种部署方式。</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="grey-wrap">
      <div class="fz-big align-ct"><h4>平台功能</h4></div>
      <div class="anchor-box">
        <div class="nav-btn"><a href="#mes">MES</a></div>
        <div class="nav-btn"><a href="#erp">ERP</a></div>
        <div class="nav-btn"><a href="#wms">WMS</a></div>
        <div class="nav-btn"><a href="#qms">QMS</a></div>
        <div class="nav-btn"><a href="#andon">Andon</a></div>
        <div class="nav-btn"><a href="#dms">DMS</a></div>
        <div class="nav-btn"><a href="#plm">PLM</a></div>
        <div class="nav-btn"><a href="#aps">APS</a></div>
        <div class="nav-btn"><a href="#dps">DPS</a></div>
        <div class="nav-btn"><a href="#les">LES</a></div>
      </div>
      <div id="mes"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic a-pic"></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>MES 生产制造执行系统</h5></div>
              <div class="txt-box">
                MES系统是一套面向制造企业车间执行层的生产信息化管理系统。MES可以为企业提供包括制造数据管理、计划排程管理、生产调度管理、库存管理、质量管理、人力资源管理、工作中心/设备管理、工具工装管理、采购管理、成本管理、项目看板管理、生产过程控制、底层数据集成分析、上层数据集成分解等管理模块，为企业打造一个扎实、可靠、全面、可行的制造协同管理平台。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="erp"></div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>ERP 企业资源计划系统</h5></div>
              <div class="txt-box">
                ERP企业资源计划系统是指建立在信息技术基础上以系统化的管理思想企业决策及员工提供决策运行手段的管理平台。ERP是一种可以提供跨地区、跨部门甚至跨公司整合实时信息的企业管理信息系统。ERP不仅仅是一个软件更重要的是一个管理思想，它实现了企业内部资源和企业相关的外部资源的整合。通过软件把企业的人、财、物、产、供销及相应的物流、信息流、资金流、管理流、增值流等紧密地集成起来实现资源优化和共享。
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic b-pic" style="margin-top: 10%"></div>
          </el-col>
        </el-row>
      </div>
      <div id="wms"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic c-pic "></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>WMS 智慧仓储</h5></div>
              <div class="txt-box">
                WMS智慧仓储旨在形成一套对条码管理、标签设计、原料入库、原料退库、生产领料、生产退料、产成品入库、销售出库、成品追溯、盘点和报表等一体化管理的WMS系统，并与现有ERP系统无缝对接，提升生产管理。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="qms"></div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>QMS 质量管理系统</h5></div>
              <div class="txt-box">
                QMS质量管理系统对新品开发过程、入厂检验过程、过程质量检验、市场质量记录、供应商质量PK、供应商质量体系建设、PPM管理等方面的数据进行综合分析，能够客观、准确、及时的对供应商实物质量及质量保证体系进行系统性评价，能够为客户在供应商质量体系建设方便提供系统性分析数据。同时通过系统与供应商进行快捷的质量交流，提高工作效率,从而提升产品的质量品质。
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic d-pic"></div>
          </el-col>
        </el-row>
      </div>
      <div id="andon"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic e-pic"></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>Andon</h5></div>
              <div class="txt-box">
                Andon系统分硬件设备部分和Andon系统软件部分；硬件设备部分全部采用无线通信技术，减少布线的人员浪费和布
                线带来的不必要的麻烦；主要的硬件设备有：无线主机、Andon系统看板、Andon系统上报装置、语音系统、报警系统、穿戴设备
                、移动终端；Andon系统软件部分由服务器软件和客户端软件组成；服务器软件复杂总的处理，控制各Andon设备，同时向车间 员工提供数据服务；客户端软件则实现客户的具体需求；如异常查询，产量查询，通知发布等。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="dms"></div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>DMS 经销商管理系统</h5></div>
              <div class="txt-box">
                DMS经销商管理系统主要包括销售业务管理、经销商管理、配件业务管理、售后业务管理、协同信息管理、系统管理等模块组成。DMS系统不仅涵盖了针对4S店的整车销售、零配件仓库、售后维修服务(含车间管理)、客服服务等，并且在主机厂和经销商之间能成功搭建一个互动交流的信息桥梁，全面满足经销商对“汽车销售、维修服务、配件供应、信息反馈、客户关系”等业务的信息化管理。DMS系统更能让经销商及时掌握市场变化、提高信息交流的时效性、压缩中间运营成本、减少资源浪费，最大限度保证在有限的投入下，实现用户的商业目标。
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic f-pic"></div>
          </el-col>
        </el-row>
      </div>
      <div id="plm"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic g-pic"></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>PLM 产品生命周期管理</h5></div>
              <div class="txt-box">
                产品生命周期管理（PLM），就是指从人们对产品的需求开始，到产品淘汰报废的全部生命历程。PLM是一种先进的企业信息化思想，它让人们思考在激烈的市场竞争中，如何用最有效的方式和手段来为企业增加收入和降低成本。主要包括：变更管理、配置管理、文档管理、项目管理、产品协同、产品构型等组成。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="aps"></div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>APS 高级计划与排程系统</h5></div>
              <div class="txt-box">
                <p>APS高级计划与排程系统基于供应链管理和约束理论，以追求精益生产(JIT)为目标，涵盖了大量的数学模型、优化及模拟技术，为复杂的生产和供 应问题提供优化解决方案，广泛适用于各类制造型企业。</p>
                <p>APS集成了产能及物料分析和评估、工序级生产和物料计划、模拟分析与计划调整、动态实时计算、动态实时计算等功能。</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic h-pic"></div>
          </el-col>
        </el-row>
      </div>
      <div id="dps"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic i-pic"></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>DPS 电子标签管理系统</h5></div>
              <div class="txt-box">
                DPS电子标签信息管理系统是分布式管理系统。它以中央计算机为上位机，通过网络通信来来管理安装在货物储位上的众多电子标签。该系统通过中央计算机控制电子标签的指示灯信号、数码显示等信号，使作业人员正确、快速、轻松地完成补货(入库)和出货(出库)任务。它具有可弹性控制作业时间、即时现场控制、紧急定单处理等功能，从而达到有效降低拣货错误率、加快拣货速度、合理安排拣货人员行走路线、提高工作效率等目的
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="les"></div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-sm align-lt"><h5>LES 物流执行系统</h5></div>
              <div class="txt-box">
                物流执行系统（LES）是以物料拉动为核心，通过智能化物料的拉动模式，在收、发、存环节实现物流精细化、精益化管理。从工厂收货，存货，订货拣选，准时出货，按序出库，动态拉动（车间拉动，产线拉动，供应商需求计划拉动）等一系列智能物流调度与控制过程，通过软件分析计算和业务优化，将内外物流的复杂环节无缝衔接，达到提高整个工厂物流效率的系统。
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic j-pic"></div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-big align-lt" style="padding-top: 8%"><h4>定制化开发</h4></div>
              <div class="txt-box dzh-box">
                <p class="em">定制的不仅仅是软件和硬件 更是一种理念</p>
                <p>定制化开发是解决企业个性化难题唯一途径，而且是企业快速发展和提高竞争力的最好方法。</p>
                <el-button type="primary" plain class="zdh-btn" @click="handleShow">立即咨询
                </el-button>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic dzh-pic"></div>
          </el-col>
        </el-row>
        <div class="fz-sm align-ct" style="padding-top: 4%"><h5>定制化特点</h5></div>
        <div class="customized-box">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-gps"></i></div>
            <p>针对性强</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-bianjie"></i></div>
            <p>操作便捷</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-price"></i></div>
            <p>性价比高</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-service"></i></div>
            <p>服务周到</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-upgrade"></i></div>
            <p>升级方便</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <BackTop ref="backTop"></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '智慧工厂-产品中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控产品智慧工厂-产品中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {
    handleShow () {
      this.$refs.backTop.handleOpen()
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.product-factory {
  .banner-factory {
    background: url("../../assets/img/product/factory/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;

    .txt {
      width: 20%;
      height: 20%;
      background: url("../../assets/img/product/factory/txt.png") no-repeat center;
      background-size: contain;
      position: absolute;
      left: 26%;
      top: 30%;
    }
  }

  .bs-info {
    margin-top: 20%;

    .fz-big {
      padding-bottom: 10%;
    }
  }

  .a-pic {
    background-image: url("../../assets/img/product/factory/case_a.png");
  }

  .b-pic {
    background-image: url("../../assets/img/product/factory/case_b.png");
  }

  .c-pic {
    background-image: url("../../assets/img/product/factory/case_c.png");
  }

  .d-pic {
    background-image: url("../../assets/img/product/factory/case_d.png");
  }

  .e-pic {
    background-image: url("../../assets/img/product/factory/case_e.png");
  }

  .f-pic {
    background-image: url("../../assets/img/product/factory/case_f.png");
  }

  .g-pic {
    background-image: url("../../assets/img/product/factory/case_g.png");
  }

  .h-pic {
    background-image: url("../../assets/img/product/factory/case_h.png");
  }

  .i-pic {
    background-image: url("../../assets/img/product/factory/case_i.png");
  }

  .j-pic {
    background-image: url("../../assets/img/product/factory/case_j.png");
  }

}
</style>
